import { SingleEliminationBracket, Match, MATCH_STATES, SVGViewer } from '@g-loot/react-tournament-brackets';

export const SingleElimination = ({matches}) => (
    <SingleEliminationBracket
      matches={matches}
      matchComponent={Match}
    //   svgWrapper={ ( { children, ...props }) => (
    //       <SVGViewer width={700} height={700} {...props}>
    //           {children}
    //       </SVGViewer>
    //   )}
      />
);
