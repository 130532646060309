import logo from './logo.svg';
import './App.css';
import { Component } from 'react';
import { SingleElimination } from './SingleElimination';



class App extends Component {


state = {
   loadComplete  : false 
};  
targetURL = `https://436u5z8vk1.execute-api.us-east-2.amazonaws.com/v1/cil-madness-contest-info/bracket.json`;

matches = [
  {
    "id" : "RX",
    "name" : "Final",
    "nextMatchId" : null,
    "tournamentRoundText" : "1",
    "startTime" : "2023-03-24",
    "state" : "NO_PARTY",
    "participants" : [
      {
        "id" : "C2",
        "resultText" : 10,
        "isWinner" : false,
        "status" : null,
        "name" : ""
      },
      {
       "id" : "C9",
       "resultText" : 6,
       "isWinner" : true,
       "status" : null,
       "name" : ""
     },
    ]
 },
]

  componentDidMount() {

    fetch(`${this.targetURL}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': '*/*',
        }
      })
      .then (response => response.json())
      .then ( (jsonData)=> {
        console.table(jsonData);
        this.matches =jsonData;
        this.setState( { loadComplete : true})
      })
    
      .catch(err => {
        console.log(err);
      })
  }

  render() {
    return (
      <div className={ this.state.loadComplete ? "App" : "hide"}>
        <a href="https://d1sh9v2kv5z7ru.cloudfront.net">Info</a>
        <SingleElimination matches={this.matches}/>
        {/* {console.table(this.matches)} */}
      </div>
    );
  }

}


export default App;

